




















































import {
  computed,
  defineComponent,
  PropType,
  ref,
  toRef,
  watch,
  nextTick,
  Ref
} from "vue";
import { IMaskComponent } from "vue-imask";
import { commaToDot, uppercase } from "@/utils/transformInput";
import insertIf from "@/utils/insertIf";
import { BaseValidation } from "@vuelidate/core";

import { generateId } from "@/utils/generateId";

export default defineComponent({
  name: "InputMask",
  components: {
    "vue-imask": IMaskComponent
  },
  props: {
    id: {
      type: String as PropType<string>,
      default: ""
    },
    label: {
      type: String as PropType<string>,
      default: ""
    },
    /**
     * If you set placeholder label must be empty
     */
    placeholder: {
      type: String as PropType<string>,
      default: ""
    },
    value: {
      type: [String, Number] as PropType<string | number>,
      default: "",
      required: true
    },
    /**
     * Set RegExp that describe what characters are able to be entered in input.
     * For example: `\d` -- one numeric digit
     */
    pattern: {
      type: String as PropType<string>
    },
    /**
     * `HTML` string that will be added after inputted text after `blur`
     * For example: `м<sup>2</sup>`
     */
    postfix: String as PropType<string>,
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    /**
     * Format function than formats value after `input`.
     * Available values: `locale`
     */
    format: {
      type: String as PropType<string>,
      validator: (val: string) => {
        return /(^locale$)/.test(val);
      }
    },
    /**
     * Available values: `'' | 'outlined' | 'registration'`
     */
    theme: {
      type: String as PropType<string>,
      default: ""
    },
    /**
     * `Input imask` property see https://imask.js.org/
     */
    mask: [String, RegExp, Number, Function] as PropType<
      string | RegExp | number | (() => string) | NumberConstructor
    >,
    /**
     * `Input imask` property that adds `_` digit at the empty mask position when `false`
     */
    lazy: {
      type: Boolean as PropType<boolean>,
      default: true
    },
    /**
     * Transform function that transform value after `input` event.
     * Available values: `'uppercase' | 'commaToDot'`
     */
    transform: {
      type: String as PropType<"uppercase" | "commaToDot" | "">,
      default: "",
      validator: (value: "uppercase" | "commaToDot" | "") => {
        return value === "uppercase" || value === "commaToDot" || value === "";
      }
    },
    /**
     * Small size of input
     */
    small: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    /**
     * `Vuelidate` meta object.
     */
    meta: {
      type: Object as PropType<BaseValidation>,
      default: null
    },
    /**
     * Error string. Used with `meta` property
     */
    error: {
      type: String as PropType<string | Ref<string>>,
      default: ""
    },
    /**
     * Prompt string.
     */
    prompt: {
      type: String as PropType<string>,
      default: ""
    },
    lazyHard: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    isRequired: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  emits: ["input", "update:data", "focus", "blur"],
  setup(props, { emit }) {
    const isBlur = ref(true);
    const val = ref("");
    const labelWidth = ref(0);

    const inputId = computed<string>(() => {
      return props.id || `input-${generateId()}`;
    });
    const isValid = computed<boolean>(() => {
      return (!props.disabled && props.meta && !props.meta.$invalid) as boolean;
    });
    const isInvalid = computed<boolean>(() => {
      return props.meta?.$error as boolean;
    });
    const classes = computed<string[]>(() => {
      return [
        "input",
        props.theme ? `input_theme_${props.theme}` : "",
        ...insertIf(props.small, "input_size_small"),
        ...insertIf(val.value.length !== 0, "input_state_active"),
        ...insertIf(props.disabled, "input_state_disabled"),
        ...insertIf(!isBlur.value, "input_state_focused"),
        ...insertIf(isValid.value && isBlur.value, "input_valid_true"),
        ...insertIf(isInvalid.value, "input_valid_false"),
        ...insertIf(
          (isInvalid.value &&
            props.error &&
            props.error.length !== 0) as boolean,
          "input_state_has-error"
        ),
        ...insertIf(
          (props.placeholder && props.placeholder.length !== 0) as boolean,
          "input_state_has-placeholder"
        )
      ] as string[];
    });
    const showPostfix = computed<boolean>(() => {
      return !!props.value && isBlur.value;
    });
    const autosizeOpts = computed<{
      comfortZone: 1;
      minWidth: string;
      maxWidth: string;
    }>(() => {
      return {
        comfortZone: 1,
        minWidth: props.postfix ? "10px" : "100%",
        maxWidth: props.postfix
          ? `calc(100% - ${props.postfix.length * 8}px)`
          : "100%"
      };
    });
    const computedLabel = computed(() => {
      return props.isRequired ? props.label + "*" : props.label;
    });

    const formatValue = (val: string): string => {
      if (!val) {
        return "";
      }
      let replacedValue = val;
      if (props.transform != null && props.transform !== "") {
        replacedValue =
          props.transform === "commaToDot" ? commaToDot(val) : uppercase(val);
      }
      if (!props.pattern) {
        return replacedValue;
      }
      replacedValue = replacedValue
        .toString()
        .replace(new RegExp(props.pattern, "g"), "");

      if (replacedValue && props.format === "locale") {
        const trimmedValue = replacedValue.replace(/\s/g, "");
        replacedValue = parseInt(trimmedValue, 10).toLocaleString("ru");
      }
      return replacedValue;
    };
    const updateValue = (val: string): string => {
      val = formatValue(val);
      return val;
    };
    const input = (value: string): void => {
      if (value === props.value) return;
      emit("input", updateValue(value));
      emit("update:data", updateValue(value));
    };
    const focus = (event: FocusEvent): void => {
      isBlur.value = false;
      props.meta?.$touch();
      emit("focus", event);
    };
    const blur = (event: FocusEvent): void => {
      isBlur.value = true;
      emit("blur", event);
    };
    watch(toRef(props, "value"), value => {
      nextTick(() => {
        value = value as string;
        updateValue(value);
        val.value = value;
      });
    });

    return {
      isBlur,
      val,
      labelWidth,
      inputId,
      isValid,
      isInvalid,
      classes,
      showPostfix,
      autosizeOpts,
      computedLabel,
      formatValue,
      input,
      blur,
      focus,
      updateValue
    };
  },
  watch: {
    label() {
      this.setLabelWidth();
    }
  },
  mounted() {
    nextTick(() => {
      const value = this.value as string;
      this.updateValue(value);
      this.val = value;
      this.setLabelWidth();
    });
  },
  methods: {
    setLabelWidth(): void {
      this.labelWidth = this.$refs.label
        ? Math.min(
            (this.$refs.label as Element).scrollWidth * 0.75 + 6,
            (this.$refs.rootEl as HTMLElement).offsetWidth - 24
          )
        : 0;
    }
  }
});
